<template>
  <Page class="page-users page-edit-user">
    <template #title>Modifica dati utente</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'users.show' }">Annulla modifica</router-link>
    </template>

    <template #default>
      <UserForm :feedback.sync="feedback" :user="userToEdit" @submitForm="submit" edit />
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import UserForm from '@/views/components/Form/UserForm.vue';

export default {
  components: {
    Page,
    UserForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    userToEdit: {},
    feedback: null,
  }),
  watch: {
    user (newValue) {
      this.updateUserToEdit(newValue);
    },
  },
  methods: {
    updateUserToEdit (user) {
      this.userToEdit = {
        ...user,
        ...this.userToEdit,
      };
    },
    submit (userToEdit) {
      const user = { ...userToEdit };

      this.$api.updateUser(user.id, user)
        .then(res => {
          this.feedback = true;
          this.$emit('update:user', res.data);
          this.userToEdit = {};
          this.$router.push({ name: 'users.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updateUserToEdit(this.user);
  },
};

</script>
